const logOut = () => {
  const csrfToken = document.querySelector('meta[name=csrf-token]').content;
  const csrfParam = document.querySelector('meta[name=csrf-param]').content;

  let form = document.createElement('form');

  let formContent = `<input name='_method' value='delete' type='hidden' />`;

  if ((csrfParam != null) && (csrfToken != null)) {
    formContent += `<input name='${csrfParam}' value='${csrfToken}' type='hidden' />`;
  }

  // Must trigger submit by click on a button, else "submit" event handler won't work!
  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/submit
  formContent += '<input type="submit" />';

  form.method = 'post';
  form.action = '/users/sign_out';
  form.target = '_self';
  form.innerHTML = formContent;
  form.style.display = 'none';

  document.body.appendChild(form);

  form.querySelector('[type="submit"]').click();
}

export default logOut;
