import React from 'react'
import { render, fireEvent, waitFor, screen } from '@testing-library/react'

// add custom jest matchers from jest-dom
import '@testing-library/jest-dom/extend-expect'

// the component to test
import UserNewFrame from './UserNewFrame'

test('loads the correct defaults in the form', async () => {
  window.matchMedia = jest.fn().mockImplementation((query) => ({
    matches: false,
    media: query,
    onchange: null,
    addListener: jest.fn(), // deprecated
    removeListener: jest.fn(), // deprecated
    addEventListener: jest.fn(),
    removeEventListener: jest.fn(),
    dispatchEvent: jest.fn(),
  }))

  const user_details = {
    name: "MF DOOM",
    vendor: "ALL CAPS Distillery",
    role: "admin",
  }
  render(<UserNewFrame user_details={user_details} />)

  expect(screen.getByRole('heading')).toHaveTextContent('New User')
  expect(screen.getByLabelText('Role')).toHaveValue('user')
  expect(screen.getByLabelText('Vendor')).toBeEmptyDOMElement()
})
