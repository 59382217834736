import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Toast} from '@shopify/polaris';

const AppToast = ({toasts}) => {
  const [noticeToastMessage, setNoticeToastMessage] = useState(toasts.notice || '');
  const [alertToastMessage, setAlertToastMessage] = useState(toasts.alert || '');

  const [noticeToastActive, setNoticeToastActive] = useState(false);
  const [alertToastActive, setAlertToastActive] = useState(false);

  const dismissNoticeToast = useCallback(() => {
    setNoticeToastMessage('');
    setNoticeToastActive(false);
  }, []);

  const dismissAlertToast = useCallback(() => {
    setAlertToastMessage('');
    setAlertToastActive(false);
  }, []);

  const noticeToastMarkup = noticeToastActive ? (
    <Toast onDismiss={dismissNoticeToast} content={noticeToastMessage} />
  ) : null;
  const alertToastMarkup = alertToastActive ? (
    <Toast error onDismiss={dismissAlertToast} content={alertToastMessage} />
  ) : null;

  useEffect(() => {
    if(!noticeToastActive && noticeToastMessage != '') {
      setNoticeToastActive(true);
    }
    if(!alertToastActive && alertToastMessage!= '') {
      setAlertToastActive(true);
    }
  });

  return(
    <>
    {noticeToastMarkup}
    {alertToastMarkup}
    </>
  );
}

export default AppToast;
