import React, {useCallback, useEffect, useState} from 'react';
import {Card, DataTable, DisplayText, SkeletonBodyText} from '@shopify/polaris';

const axios = require('axios');
const queryString = require('query-string');

const SalesCard = ({ selectedVendor, start, end }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [totalSales, setTotalSales] = useState('0');
  const [salesByProduct, setSalesByProduct] = useState([]);
  const [salesByState, setSalesByState] = useState([]);
  const [salesByReferrer, setSalesByReferrer] = useState([]);
  const [customerReturnRate, setCustomerReturnRate] = useState('0%');

  const parseLineItems = (line_items_data) => {
    let lineitem_array = [];
    for (const [name, values] of Object.entries(line_items_data)) {
      lineitem_array.push([name, values.price, values.quantity, values.total]);
    }

    return lineitem_array;
  }

  const totalSalesMarkup = <DisplayText size="large">{totalSales}</DisplayText>;
  const returnCustomerRateMarkup = <DisplayText size="large">{customerReturnRate}</DisplayText>;

  const salesByStateMarkup = (
    <DataTable
      columnContentTypes={[
        'text',
        'numeric',
      ]}
      headings={[
        'State',
        'Total orders',
      ]}
      rows={salesByState}
    />
  );

  const salesByReferrerMarkup = (
    <DataTable
      columnContentTypes={[
        'text',
        'numeric',
      ]}
      headings={[
        'Referral Source',
        'Total orders',
      ]}
      rows={salesByReferrer}
    />
  );

  const [sortedRows, setSortedRows] = useState(null);
  const rows = sortedRows ? sortedRows : sortCurrency(salesByProduct, 3, 'descending');

  const handleSort = useCallback(
    (index, direction) => {
      console.log(index, direction);
      if(index == 3) {
        setSortedRows(sortCurrency(rows, index, direction))
      } else {
        setSortedRows(sortName(rows, index, direction));
      }
    },
    [rows],
  );

  const salesByProductMarkup = (
    <DataTable
      columnContentTypes={[
        'text',
        'numeric',
        'numeric',
        'numeric',
      ]}
      headings={[
        'Product',
        'Price',
        'Total quantity',
        'Total sales',
      ]}
      sortable={[true, false, false, true]}
      defaultSortDirection="descending"
      initialSortColumnIndex={3}
      onSort={handleSort}
      rows={rows}
    />
  );

  function sortCurrency(rows, index, direction) {
    return [...rows].sort((rowA, rowB) => {
      const amountA = parseFloat(rowA[index].replace(/(^\$|,)/g,''));
      const amountB = parseFloat(rowB[index].replace(/(^\$|,)/g,''));

      return direction === 'descending' ? amountB - amountA : amountA - amountB;
    });
  }

  function sortName(rows, index, direction) {
    return [...rows].sort((rowA, rowB) => {
      var nameA = rowA[index].toUpperCase(); // ignore upper and lowercase
      var nameB = rowB[index].toUpperCase(); // ignore upper and lowercase
      if ((direction === 'ascending' && nameA < nameB) || (nameA > nameB)) {
        return -1;
      }
      if ((direction === 'ascending' && nameA > nameB) || (nameA < nameB)) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  }

  useEffect(() => {
    let filterQuery = queryString.stringify({
      vendor: selectedVendor,
      start: start,
      end: end,
    })


    const fetchData = async () => {
      axios.get('/api/metrics/sales?'.concat(filterQuery))
        .then(response => {
          setTotalSales(response.data.sales.total_quantity);
          setSalesByProduct(parseLineItems(response.data.sales.line_items));
          setSalesByState(response.data.sales.orders_by_state);
          setSalesByReferrer(response.data.sales.referral_sources);
          setCustomerReturnRate(response.data.sales.return_customer_rate);
          setIsLoading(false);
        })
        .catch(error => {
          console.log(error);
        })
    };

    fetchData();
  }, []);


  return(
    <>
      <Card title="Total Sales">
        <Card.Section>
          <p>
            This is the total number of products sold.
          </p>
        </Card.Section>
        <Card.Section title="Total Products Sold">
          {isLoading ? <SkeletonBodyText /> : totalSalesMarkup}
        </Card.Section>
      </Card>
      <Card title="Total Sales by Product">
        <Card.Section>
          <p>
            These are your sales by products in Seelbach's.
          </p>
        </Card.Section>
        <Card.Section>
          {isLoading ? <SkeletonBodyText /> : salesByProductMarkup}
        </Card.Section>
      </Card>
      <Card title="Total Sales by State">
        <Card.Section>
          <p>
            These are your sales by state in Seelbach's.
          </p>
        </Card.Section>
        <Card.Section>
          {isLoading ? <SkeletonBodyText /> : salesByStateMarkup}
        </Card.Section>
      </Card>
      <Card title="Referral Sources">
          {isLoading ? <SkeletonBodyText /> : salesByReferrerMarkup}
      </Card>
      <Card title="Returning Customers">
        <Card.Section title="Returning Customer Rate">
          {isLoading ? <SkeletonBodyText /> : returnCustomerRateMarkup}
        </Card.Section>
      </Card>
    </>
  );
}

export default SalesCard;
