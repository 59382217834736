import React, {useCallback, useRef, useState} from 'react';
import {Navigation} from '@shopify/polaris';
import {CustomersMajor, HomeMajor, TransferInMajor} from '@shopify/polaris-icons';

const AppNavigation = ({role}) => {
  return (
    <Navigation location="/">
      <Navigation.Section
        separator
        items={[
          {
            url: '/dashboard',
            label: 'Dashboard',
            icon: HomeMajor,
          },
        ]}
      />
      {role == 'admin' && <AdminSection />}
    </Navigation>
  );
}

const AdminSection = () => {
  return (
    <Navigation.Section
      separator
      title="Admin"
      items={[
        {
          url: '/admin/users',
          label: 'Users',
          icon: CustomersMajor,
        },
      ]}
    />
  );
}

export default AppNavigation;
