import React, {useCallback, useState} from 'react';
import {Avatar, Badge, Card, ResourceItem, ResourceList, TextContainer, Layout, Page, TextStyle} from '@shopify/polaris';

import AppFrame from '../AppFrame';

const UsersFrame = ({users_details, user_details}) => {
  const pageMarkup = (
    <Page
    title="User"
    primaryAction={{
      content: 'Create User',
      url: '/admin/users/new',
    }}
    >
      <Card>
        <ResourceList
          resourceName={{singular: 'customer', plural: 'customers'}}
          items={users_details}
          renderItem={ (item) => {
            const {id, name, vendor, role} = item;
            const media = <Avatar customer size="medium" name={name} />;

            return (
              <ResourceItem
                id={id}
                url={`/admin/users/${id}/edit`}
                media={media}
                verticalAlignment="center"
                accessibilityLabel={`View details for ${name}`}
              >
                <h3>
                  <TextStyle variation="strong">{name}</TextStyle>
                  <Badge status="info">{role}</Badge>
                </h3>
                <div>{vendor}</div>
              </ResourceItem>
            );
          } }
        />
      </Card>
    </Page>
  );

  return (
    <div style={{height: '500px'}}>
      <AppFrame
      pageMarkup={pageMarkup}
      userDetails={user_details}
      />
    </div>
  );
}

export default UsersFrame;
