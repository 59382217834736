import React from 'react';
import {Card, DatePicker, Icon, Popover, Layout, Page, Select, Stack, Badge, Button, SkeletonBodyText} from '@shopify/polaris';
import {useCallback, useRef, useState} from 'react';
import {CalendarMajor} from '@shopify/polaris-icons';

const queryString = require('query-string');

const DashboardFilter = ({ userDetails, vendors, selectedVendor, startDate, endDate }) => {
  const [selected, setSelected] = useState(selectedVendor);
  const handleSelectChange = useCallback((value) => setSelected(value), []);
  const options = vendors;

  const isAdmin = userDetails.role == "admin";

  const [selectedDates, setSelectedDates] = useState({
    start: new Date(startDate),
    end: new Date(endDate),
  });
  const [{month, year}, setDate] = useState({month: selectedDates.start.getMonth(), year: selectedDates.start.getFullYear()});

  const handleMonthChange = useCallback( (month, year) => setDate({month, year}), [],);

  const [popoverActive, setPopoverActive] = useState(false);
  const [tagValue, setTagValue] = useState('');

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );

  const handleTagValueChange = useCallback((value) => setTagValue(value), []);

  const filterQuery = () => {
    let attributes = {
      vendor: selected,
      start: dateFormat(selectedDates.start),
      end: dateFormat(selectedDates.end),
    }
    return '/?'.concat(queryString.stringify(attributes)) 
  }

  const dateFormat = (date) => {
    return `${ date.getFullYear() }-${ date.getMonth() + 1 }-${ date.getDate() }`
  }

  const activator = (
    <Button
      onClick={togglePopoverActive}
      icon={<Icon source={CalendarMajor} />}
    >
      Filter Dates
    </Button>
  );

  const adminFilters = (
    <Select
      label="Vendor"
      options={options}
      onChange={handleSelectChange}
      value={selected}
    />
  );

  const filters = (
    <div id="dashboard-actions">
      <Stack spacing="tight" alignment="trailing">
        { isAdmin && adminFilters }
        <Popover
          active={popoverActive}
          activator={activator}
          onClose={togglePopoverActive}
          ariaHaspopup={false}
          sectioned
        >
          <DatePicker
            month={month}
            year={year}
            onChange={setSelectedDates}
            onMonthChange={handleMonthChange}
            selected={selectedDates}
            multiMonth
            allowRange
          />
        </Popover>
        <Button
          accessibilityLabel="update-dashboard"
          primary="true"
          url={ filterQuery() }
        >Update</Button>
      </Stack>
    </div>
  )

  return filters;
}

export default DashboardFilter;
