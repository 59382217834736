import React, {useCallback, useRef, useState} from 'react';
import {Heading, AppProvider, Avatar, ActionList, Button, Card, Icon, Select, TextField, TextContainer, ContextualSaveBar, Form, FormLayout, Frame, Layout, Loading, Page, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, Toast, TopBar} from '@shopify/polaris';
import {ClipboardMinor} from '@shopify/polaris-icons';

import LimitedAppFrame from '../LimitedAppFrame';
import AppToast from '../AppToast';

const ActivateFrame = ({user_details, token, toasts}) => {
  const [isLoading, setIsLoading] = useState(false);

  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    [],
  );

  const skipToContentRef = useRef(null);

  const [passwordFieldValue, setPasswordFieldValue] = useState('');
  const [passwordConfirmationFieldValue, setPasswordConfirmationFieldValue] = useState('');

  const handlePasswordFieldChange = useCallback((value) => {
    setPasswordFieldValue(value);
  }, []);
  const handlePasswordConfirmationFieldChange = useCallback((value) => {
    setPasswordConfirmationFieldValue(value);
  }, []);

  const loadingMarkup = isLoading ? <Loading /> : null;

  const skipToContentTarget = (
    <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
  );

  const [userMenuActive, setUserMenuActive] = useState(false);
  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    [],
  );

  const userMenuMarkup = (
    <TopBar.UserMenu
      name={user_details.name}
      detail={user_details.vendor}
      initials={user_details.initial}
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  );

  const actualPageMarkup = (
    <>
      <Page title="Complete Setup">
        <Card sectioned>
          <TextContainer>
            <Heading>Complete Your Account Setup</Heading>
            <p>
              Provide a password to complete your account setup.
            </p>
          </TextContainer>
          <Form action="/activate" preventDefault={false} autoComplete="off">
            <FormLayout>
              <input type="hidden" name="authenticity_token" value={token}/>
              <input type="hidden" name="invitation_token" value={user_details.invitation_token}/>
              <FormLayout.Group>
                <TextField
                  value={passwordFieldValue}
                  onChange={handlePasswordFieldChange}
                  name="password"
                  label="Password"
                  type="password"
                  autoComplete="new-password"
                />
                <TextField
                  value={passwordConfirmationFieldValue}
                  onChange={handlePasswordConfirmationFieldChange}
                  name="password_confirmation"
                  label="Password Confirmation"
                  type="password"
                  autoComplete="new-password"
                />
              </FormLayout.Group>
              <Button primary submit>Save Password</Button>
            </FormLayout>
          </Form>
        </Card>
      </Page>
      <AppToast toasts={toasts} />
    </>
  );

  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <LimitedAppFrame
      loadingMarkup={loadingMarkup}
      pageMarkup={pageMarkup}
      userMenuMarkup={userMenuMarkup}
    />
  );
}

export default ActivateFrame;
