import React, {useCallback, useRef, useState} from 'react';
import {AppProvider, ContextualSaveBar, Frame, TopBar} from '@shopify/polaris';
import {Navigation} from '@shopify/polaris';
import en from "@shopify/polaris/locales/en.json";

const LimitedAppFrame = ({ handleSave, handleDiscard, isDirty, pageMarkup, userMenuMarkup }) => {
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive,
      ),
    [],
  );

  const topBarMarkup = (
    <TopBar
      userMenu={userMenuMarkup}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );

  // APP - Save Related
  // ------------------------------

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Unsaved changes"
      saveAction={{
        onAction: handleSave,
      }}
      discardAction={{
        onAction: handleDiscard,
      }}
    />
  ) : null;

  const theme = {
    colors: {
      topBar: {
        background: '#357997',
      },
    },
    logo: {
      width: 124,
      topBarSource: '/seelbachs-logo.png',
      contextualSaveBarSource: '/seelbachs-logo-inverted.png',
      url: '/',
      accessibilityLabel: "Seelbach's Reports",
    },
  };

  return (
    <AppProvider
      theme={theme}
      i18n={en}
    >
      <Frame
        topBar={topBarMarkup}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
      >
        {contextualSaveBarMarkup}
        {pageMarkup}
      </Frame>
    </AppProvider>
  );
}

export default LimitedAppFrame;
