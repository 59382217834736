import React, {useCallback, useRef, useState} from 'react';
import {Heading, CalloutCard, AppProvider, DisplayText, Avatar, ActionList, Button, Card, Icon, Select, TextField, TextContainer, ContextualSaveBar, Form, FormLayout, Frame, Layout, Loading, Page, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, Toast, TopBar} from '@shopify/polaris';

import LimitedAppFrame from '../LimitedAppFrame';

const ErrorFrame = ({errorMessage}) => {
  const pageMarkup = (
    <Page>
      <CalloutCard
      title="Something went wrong."
      illustration="/warning.svg"
      primaryAction={{
        content: 'Email Blake',
        url: 'mailto:blake@seelbachs.com',
      }}
    >
        <p>{errorMessage}</p>
        <p>If you think there is a problem, please contact Blake.</p>
      </CalloutCard>
    </Page>
  );

  return (
    <LimitedAppFrame
      pageMarkup={pageMarkup}
    />
  );
}

export default ErrorFrame;
