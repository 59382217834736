import React from 'react';
import {Card, Layout, Page, SkeletonBodyText} from '@shopify/polaris';

import AppFrame from '../AppFrame';
import AppToast from '../AppToast';
import SalesCard from '../metrics/SalesCard';

import DashboardFilter from './DashboardFilter';

import {useCallback, useRef, useState} from 'react';

const DashboardFrame = ({user_details, toasts, vendors, selected_vendor, start_date, end_date}) => {
  const pageMarkup = (
    <>
    <Page title="Dashboard">
      <DashboardFilter
        userDetails={user_details}
        vendors={vendors}
        selectedVendor={selected_vendor}
        startDate={start_date}
        endDate={end_date}
      />
      <Layout>
        <Layout.Section>
          <SalesCard
            selectedVendor={selected_vendor}
            start={start_date}
            end={end_date}
          />
        </Layout.Section>
      </Layout>
    </Page>
    <AppToast toasts={toasts} />
    </>
  );

  return (
    <AppFrame
      pageMarkup={pageMarkup}
      userDetails={user_details}
    />
  );
}

export default DashboardFrame;
