import React, {useCallback, useRef, useState} from 'react';
import {AppProvider, ContextualSaveBar, Frame, TopBar} from '@shopify/polaris';
import en from "@shopify/polaris/locales/en.json";
import {LogOutMinor} from '@shopify/polaris-icons';

import logOut from '../../utils/logOut';
import AppNavigation from  './AppNavigation';

const AppFrame = ({ handleSave, handleDiscard, isDirty, pageMarkup, userDetails }) => {
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    [],
  );

  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive,
      ),
    [],
  );

  const userMenuActions = [
    {
      items: [{content: 'Log Out', icon: LogOutMinor, onAction: logOut, id: 'log-out-button'}],
    },
  ];

  const userMenuData = {
    userName: 'Blake',
    initials: 'B',
    storeName: "Seelbach's",
  }

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={userDetails.name}
      detail={userDetails.vendor}
      initials={userDetails.initial}
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );

  // APP - Toast Related
  // ------------------------------

  // APP - Save Related
  // ------------------------------

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Unsaved changes"
      saveAction={{
        onAction: handleSave,
      }}
      discardAction={{
        onAction: handleDiscard,
      }}
    />
  ) : null;

  const navigation = <AppNavigation role={userDetails.role} />;

  const theme = {
    colors: {
      topBar: {
        background: '#357997',
      },
    },
    logo: {
      width: 124,
      topBarSource: '/seelbachs-logo.png',
      contextualSaveBarSource: '/seelbachs-logo-inverted.png',
      url: '/',
      accessibilityLabel: "Seelbach's Reports",
    },
  };

  return (
    <AppProvider
      theme={theme}
      i18n={en}
    >
      <Frame
        topBar={topBarMarkup}
        navigation={navigation}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
      >
        {contextualSaveBarMarkup}
        {pageMarkup}
      </Frame>
    </AppProvider>
  );
}

export default AppFrame;
