import React, {useCallback, useRef, useState} from 'react';
import {AppProvider, Avatar, ActionList, Button, Card, Icon, Select, TextField, TextContainer, ContextualSaveBar, FormLayout, Frame, Layout, Loading, Page, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, Toast, TopBar} from '@shopify/polaris';
import {ClipboardMinor} from '@shopify/polaris-icons';

import AppFrame from '../AppFrame';
import AppToast from '../AppToast';

const UserEditFrame = ({edit_user_details, user_details, vendor_options, toasts}) => {
  const [isLoading, setIsLoading] = useState(false);

  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    [],
  );

  const [isDirty, setIsDirty] = useState(false);
  const invitationLink = edit_user_details.invitation_link;
  const defaultState = useRef({
    emailFieldValue: edit_user_details.email,
    nameFieldValue: edit_user_details.name,
    vendorFieldValue: edit_user_details.vendor_id || '',
    roleValue: edit_user_details.role,
  });
  const skipToContentRef = useRef(null);

  const [nameFieldValue, setNameFieldValue] = useState(
    defaultState.current.nameFieldValue,
  );
  const [emailFieldValue, setEmailFieldValue] = useState(
    defaultState.current.emailFieldValue,
  );
  const [vendorFieldValue, setVendorFieldValue] = useState(
    defaultState.current.vendorFieldValue,
  );
  const [selectedRole, setSelectedRole] = useState(
    defaultState.current.roleValue,
  );
  const [vendorOptions, setVendorOptions] = useState(vendor_options);

  const handleNameFieldChange = useCallback((value) => {
    setNameFieldValue(value);
    value && setIsDirty(true);
  }, []);
  const handleEmailFieldChange = useCallback((value) => {
    setEmailFieldValue(value);
    value && setIsDirty(true);
  }, []);
  const handleVendorFieldChange = useCallback((value) => {
    setVendorFieldValue(value);
    value && setIsDirty(true);
  }, []);
  const handleSelectRoleChange = useCallback((value) => {
    setSelectedRole(value)
    value && setIsDirty(true);
  }, []);
  const handleRefreshVendorAction = () => {
    let response = postVendorSync().then(data => {
      setVendorOptions(data.vendors);
    });
  }

  const postVendorSync = () => {
    const response = fetch(`/api/vendors/sync`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(data => {
      let response_data = data.json()
      return response_data;
    });

    return response;
  }


  const roleOptions = [
    {label: 'User', value: 'user'},
    {label: 'Admin', value: 'admin'},
  ];

  const handleDiscard = useCallback(() => {
    setEmailFieldValue(defaultState.current.emailFieldValue);
    setNameFieldValue(defaultState.current.nameFieldValue);
    setVendorFieldValue(defaultState.current.vendorFieldValue);
    setSelectedRole(defaultState.current.selectedRole);
    setIsDirty(false);
  }, []);

  const handleSave = useCallback(() => {
    let response = postUpdates().then(data => {
      return data.json();
    });

    defaultState.current.nameFieldValue = nameFieldValue;
    defaultState.current.emailFieldValue = emailFieldValue;
    defaultState.current.vendorFieldValue = vendorFieldValue;
    defaultState.current.role = selectedRole;

    setIsDirty(false);

    setToastActive(true);
  }, [emailFieldValue, nameFieldValue, vendorFieldValue, selectedRole]);

  const postUpdates = () => {
    const response = fetch(`/admin/users/${edit_user_details.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updatePayload())
    })

    return response;
  }

  const updatePayload = () => {
    const current_state = defaultState.current;
    const data = {
      authenticity_token: document.querySelector('meta[name=csrf-token]').content,
      name: nameFieldValue,
      email: emailFieldValue,
      vendor_id: vendorFieldValue,
      role: selectedRole,
    }

    console.log(data);

    return data;
  }

  const [toastActive, setToastActive] = useState(false);

  const toggleToastActive = useCallback(
    () => setToastActive((toastActive) => !toastActive),
    [],
  );

  const toastMarkup = toastActive ? (
    <Toast onDismiss={toggleToastActive} content="Changes saved" />
  ) : null;

  const loadingMarkup = isLoading ? <Loading /> : null;

  const skipToContentTarget = (
    <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
  );

  function copyInvitationLink() {
    navigator.clipboard.writeText(invitationLink);
  }

  const copyInvitationButton = (
    <Button
      icon={<Icon source={ClipboardMinor} />}
      onClick={copyInvitationLink}
    >Copy Invitation Link</Button>
  );

  const actualPageMarkup = (
    <>
    <Page title="Account">
      <Layout>
        {skipToContentTarget}
        <Layout.AnnotatedSection
          title="Account details"
          description="Seelbach's will use this as your account information for accessing reports."
        >
          <Card sectioned>
            <FormLayout>
              <TextField
                label="Full name"
                value={nameFieldValue}
                onChange={handleNameFieldChange}
              />
              <TextField
                type="email"
                label="Email"
                value={emailFieldValue}
                onChange={handleEmailFieldChange}
              />
              <Select
                label="Vendor"
                labelAction={{content: 'Refresh Vendor List', onAction: handleRefreshVendorAction}}
                name="vendor_id"
                options={vendorOptions}
                onChange={handleVendorFieldChange}
                value={vendorFieldValue}
              />
              <Select
                label="Role"
                name="role"
                options={roleOptions}
                onChange={handleSelectRoleChange}
                value={selectedRole}
              />
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Account Status"
        >
          <Card sectioned>
            <FormLayout>
              <TextField
                id="invitation-link"
                label="Invitation Link"
                value={edit_user_details.invitation_link}
                connectedRight={copyInvitationButton}
                disabled
              />
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
      {toastMarkup}
    </Page>
    <AppToast toasts={toasts} />
    </>
  );

  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <AppFrame
      handleSave={handleSave}
      handleDiscard={handleDiscard}
      isDirty={isDirty}
      defaultState={defaultState}
      loadingMarkup={loadingMarkup}
      pageMarkup={pageMarkup}
      skipToContentRef={skipToContentRef}
      userDetails={user_details}
    />
  );
}

export default UserEditFrame;
