import React, {useCallback, useRef, useState} from 'react';
import {AppProvider, Avatar, ActionList, Button, Card, Select, TextField, TextContainer, ContextualSaveBar, Form, FormLayout, Frame, Layout, Loading, Page, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, Toast, TopBar} from '@shopify/polaris';
import {ArrowLeftMinor, ConversationMinor, HomeMajorMonotone, OrdersMajorTwotone} from '@shopify/polaris-icons';

import AppFrame from '../AppFrame';

const SyncsFrame = ({user_details, last_order, token}) => {
  const [isLoading, setIsLoading] = useState(false);

  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    [],
  );

  const defaultState = useRef({
    last_order_date: last_order.sold_at,
    cursorFieldValue: last_order.cursor,
  });
  const skipToContentRef = useRef(null);

  const [cursorFieldValue, setCursorFieldValue] = useState(
    defaultState.current.cursorFieldValue,
  );

  const handleCursorFieldChange = useCallback((value) => {
    setCursorFieldValue(value);
  }, []);

  const postUpdates = () => {
    const response = fetch(`/admin/sync`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updatePayload())
    })

    return response;
  }

  const updatePayload = () => {
    const current_state = defaultState.current;
    const data = {
      authenticity_token: document.querySelector('meta[name=csrf-token]').content,
      cursor: cursorFieldValue,
    }

    console.log(data);

    return data;
  }

  const [toastActive, setToastActive] = useState(false);

  const toggleToastActive = useCallback(
    () => setToastActive((toastActive) => !toastActive),
    [],
  );

  const toastMarkup = toastActive ? (
    <Toast onDismiss={toggleToastActive} content="Changes saved" />
  ) : null;

  const loadingMarkup = isLoading ? <Loading /> : null;

  const skipToContentTarget = (
    <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
  );

  const actualPageMarkup = (
    <Page title="Sync">
      <Layout>
        {skipToContentTarget}
        <Layout.AnnotatedSection
          title="Syncing Data"
          description="This page allows you to manually sync data."
        >
          <Card title="Sync Orders">
            <Card.Section title="Last Order">
              <p>Order Date: {last_order.sold_at}</p>
            </Card.Section>
            <Card.Section title="Form">
              <Form action="/admin/sync" preventDefault={false} autoComplete="off">
                <input type="hidden" name="authenticity_token" value={token}/>
                <FormLayout>
                  <TextField
                    value={cursorFieldValue}
                    onChange={handleCursorFieldChange}
                    name="cursor"
                    label="Cursor"
                  />
                  <Button primary submit>Sync Data</Button>
                </FormLayout>
              </Form>
              <hr/>
              <Form action="/admin/sync_attributes" preventDefault={false} autoComplete="off">
                <input type="hidden" name="authenticity_token" value={token}/>
                <Button secondary submit>Sync Attributes</Button>
              </Form>
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
      {toastMarkup}
    </Page>
  );

  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <AppFrame
      loadingMarkup={loadingMarkup}
      pageMarkup={pageMarkup}
      skipToContentRef={skipToContentRef}
      userDetails={user_details}
    />
  );
}

export default SyncsFrame;
