import React, {useCallback, useRef, useState} from 'react';
import {AppProvider, Avatar, ActionList, Badge, Button, Card, Form, Icon, ResourceItem, ResourceList, TextField, TextContainer, ContextualSaveBar, FormLayout, Frame, Label, Layout, Loading, Page, Select, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, TextStyle, Toast, TopBar} from '@shopify/polaris';
import {RefreshMinor} from '@shopify/polaris-icons';

import AppFrame from '../AppFrame';
import AppToast from '../AppToast';

const UserNewFrame = ({users_details, user_details, token, vendor_options, toasts}) => {
  // PAGE Related
  // ================================================================================
  const [isLoading, setIsLoading] = useState(false);

  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    [],
  );

  const loadingMarkup = isLoading ? <Loading /> : null;

  const [nameFieldValue, setNameFieldValue] = useState('');
  const [emailFieldValue, setEmailFieldValue] = useState('');
  const [vendorFieldValue, setVendorFieldValue] = useState('');
  const [selectedRole, setSelectedRole] = useState('user');
  const [vendorOptions, setVendorOptions] = useState(vendor_options);

  const handleNameFieldChange = useCallback((value) => {
    setNameFieldValue(value);
  }, []);
  const handleEmailFieldChange = useCallback((value) => {
    setEmailFieldValue(value);
  }, []);
  const handleVendorFieldChange = useCallback((value) => {
    setVendorFieldValue(value);
  }, []);
  const handleSelectRoleChange = useCallback((value) => setSelectedRole(value), []);
  const handleRefreshVendorAction = () => {
    let response = postVendorSync().then(data => {
      setVendorOptions(data.vendors);
    });
  }

  const postVendorSync = () => {
    const response = fetch(`/api/vendors/sync`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(data => {
      let response_data = data.json()
      return response_data;
    });

    return response;
  }


  const roleOptions = [
    {label: 'User', value: 'user'},
    {label: 'Admin', value: 'admin'},
  ];

  const handleSubmit = () => {
  }

  const actualPageMarkup = (
    <>
    <Page title="New User">
      <Card sectioned>
        <Form action="/admin/users" preventDefault={false} autoComplete="off">
          <FormLayout>
            <input type="hidden" name="authenticity_token" value={token}/>
            <TextField
              value={nameFieldValue}
              onChange={handleNameFieldChange}
              name="name"
              label="Name"
            />
            <TextField
              value={emailFieldValue}
              onChange={handleEmailFieldChange}
              label="Email"
              name="email"
              type="email"
            />
              <Select
                id="vendor-field"
                label="Vendor"
                labelAction={{content: 'Refresh Vendor List', onAction: handleRefreshVendorAction}}
                name="vendor_id"
                options={vendorOptions}
                onChange={handleVendorFieldChange}
                value={vendorFieldValue}
                required
              />
            <Select
              id="role-field"
              label="Role"
              name="role"
              options={roleOptions}
              onChange={handleSelectRoleChange}
              value={selectedRole}
            />
            <Button primary submit>Create User</Button>
          </FormLayout>
        </Form>
      </Card>
    </Page>
    <AppToast toasts={toasts} />
    </>
  );

  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <div style={{height: '500px'}}>
      <AppFrame
      loadingMarkup={loadingMarkup}
      pageMarkup={pageMarkup}
      userDetails={user_details}
      />
    </div>
  );
}

export default UserNewFrame;
